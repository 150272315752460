import styled from "@emotion/styled";
import { AppBar, Paper } from "@mui/material";

export const StyledNavbar = styled(AppBar)({
    flexGrow: 1, 
    minHeight: '1rem', 
    backgroundColor: 'black'
})

export const PageContainerPaper = styled(Paper)({
    
})