import { Divider, Grid, Paper, Typography } from "@mui/material";
import { Suspense } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import ImageCarousel from "../components/ImageCarousel";
import { LanguageContext } from '../context/LanguageContext';
import { useContext, useState, useEffect } from "react";
import localeImages from "../static/images";
import loadImages from "../static/images";


const Home = () => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext)
    const [images, setImages] = useState([])


    useEffect(() => {
        if (language) {
            const localeImages = loadImages(language)
            setImages(localeImages)
        }
    }, [language])


    return (
        <Suspense fallback='loading'>
            <Paper elevation={1}>
                <Grid item xs={12} sx={{mt:-0.2}} >
                    <img src="/images/website-banner.svg" style={{ width: '100%' }} />
                </Grid>
                <Grid xs={12} sx={{mx:0.5}}>
                    <Grid container item justifyContent={t('flex-start')} spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant="h2" align={`${t('align')}`} >{t('missionTitle')}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mx: 2 }} >
                            <Typography variant="h6" align={`${t('align')}`} >{t('mission')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <Divider sx={{ mt: 1, mb: 6 }} width='50%' />
                    </Grid>
                    <Grid container item justifyContent={t('flex-start')} spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant="h2" align={`${t('align')}`} >{t('visionTitle')}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mx: 2 }} >
                            <Typography variant="h6" align={`${t('align')}`} >{t('vision')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent='center'>
                        <Divider sx={{ mt: 1, mb: 6 }} width='50%' />
                    </Grid>
                    <Grid container item justifyContent={t('flex-start')}>
                        <Grid item sx={12}>
                            <Typography variant="h2" align={`${t('align')}`} >{t('solutionsTitle')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {images ? <ImageCarousel items={images} /> : <></>}
            </Paper>
        </Suspense >
    )
}

export default Home;