
import { Grid, Button, Typography } from "@mui/material";
import { useContext } from "react";

const Item = ({ item }) => {
    return (
        <Grid container justifyContent="center">
            <Grid item >
                <img src={item.src} alt={item.alt} width='100%' />
            </Grid>
            <Grid item > 
                <Typography variant="h6" >{item.description}</Typography>
            </Grid>
        </Grid>
    )
}

export default Item;