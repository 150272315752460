import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar"
import Footer from "./components/Footer";

const AppRoutes = () => {
    return (

        <>
            <Navbar />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/world" element={<h1>Hello World</h1>} />
                </Routes>
            </Router>
            <Footer/>
        </>
    )
}

export default AppRoutes;