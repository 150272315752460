import { AppBar, Box, Grid, Toolbar, Typography } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import { StyledNavbar } from "../styles/styles";
import { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { useTranslation, withTranslation } from "react-i18next";


const Navbar = () => {
    const { language, setLanguage } = useContext(LanguageContext)
    const { t, i18n } = useTranslation();
    const changeLanguage = () => {
        if (language === 'ar') {
            i18n.changeLanguage('en')
            setLanguage('en')
        }
        else {
            i18n.changeLanguage('ar')
            setLanguage('ar')
        }
    }

    return (
        <StyledNavbar position="sticky">
            <Toolbar disableGutters>
                <Grid container justifyContent="space-between" alignItems={"center"}>
                    <Grid item sx={{ m: '1rem' }}>
                        <img src="/images/parrot-logo.svg" height={"50px"} />
                    </Grid>
                    <Grid  item sx={{ m: '1rem' }} >
                        <LanguageIcon onClick={() => { changeLanguage() }} fontSize="large"  />
                    </Grid>
                </Grid>
            </Toolbar>
        </StyledNavbar>
    )
}

export default Navbar;