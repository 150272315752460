import { Paper, Grid, Typography, Link } from "@mui/material"
import { useTranslation } from "react-i18next";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
    const { t } = useTranslation()

    return (
        <Grid container sx={{ background: '#222222', py: 8 }} justifyContent="spacebetween">
            <Grid container item xs={6} justifyContent="center">
                <Grid item justifyContent="center" xs={12}>
                    <Typography sx={{ color: '#515661', fontSize: '16pt', textAlign: "center" }}>{t('contact')}</Typography>
                </Grid>
                <Grid item justifyContent="center" xs={12}>
                    <Typography sx={{ color: '#515661', fontSize: '10pt', textAlign: "center" }}>{t('mobile')}: +201005177965</Typography>
                </Grid>
                <Grid item justifyContent="center" xs={12}>
                    <Typography sx={{ color: '#515661', fontSize: '10pt', textAlign: "center" }}>{t('email')}: parrotprogramming@gmail.com</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={6} justifyContent="center">
                <Grid item justifyContent="center" xs={12}>
                    <Typography sx={{ color: '#515661', fontSize: '16pt', textAlign: "center" }}>{t('findUs')}</Typography>
                </Grid>
                <Grid container item xs={12} justifyContent="center" sx={{ ml: "2.7rem", mt: 3 }}>
                    <Grid item xs={4} md={1} lg={1}>
                        <Link
                            href="https://www.facebook.com/parrotprogramming?mibextid=LQQJ4d"
                            target="_blank"
                        >
                            <FacebookIcon color="success" />
                        </Link>
                    </Grid>
                    <Grid item xs={4} md={1} lg={1}>
                        <Link
                            href="https://instagram.com/parrotprogramming_eg?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                            target="_blank"
                        >
                            <InstagramIcon color="success" />
                        </Link>
                    </Grid>
                    <Grid item xs={4} md={1} lg={1}>
                        <TwitterIcon color="success" />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default Footer;