import {createRoot} from 'react-dom/client';
import App from "./App"

import './i18n';
import Navbar from './components/Navbar';

const rootElement = document.getElementById('App');
const root = createRoot(rootElement);

root.render(
    <div className="App">
        <App />
    </div>
)