import Carousel from 'react-material-ui-carousel';
import Item from './Item';
import { Grid } from '@mui/material';

const ImageCarousel = ({ items }) => {

    return (
        <Grid container item xs={12} justifyContent='center' >
            <Grid container item xs={12} md={6} lg={6} >
                <Carousel sx={{ width: '100%' }}>
                    {items ?
                        items.map((item) => <Item key={item.id} item={item} />)
                        :
                        <></>
                    }
                </Carousel>
            </Grid>
        </Grid>
    )
}

export default ImageCarousel;