import { createTheme } from "@mui/material";
// Supports weights 400-900
import '@fontsource-variable/orbitron';

const theme = createTheme({
    palette: {
        mode: 'light',
        background: {
            // paper:'#4d8ecb',
            // default: '#4d8ecb'
        },
    },
    typography:{
        fontFamily: ['Orbitron Variable','"Times New Roman"', 'sans-serif'].join(','),
        h2: {
            fontSize: "32pt"
        },
        h6: {
            fontSize: "12pt"
        }
    }
})

export default theme;