const loadImages = (Language) => {
    return ([
        {
            id: 1,
            src: `/images/erp-auditor-${Language}.jpeg`,
            description: Language === 'ar' ? `"بناء تطبيق الخاص بك"` : 'Building Your Own Application'

        },
        {
            id: 2,
            src: `/images/appdev-${Language}.jpg`,
            description: Language === 'ar' ? `بناء تطبيق الخاص بك` : 'Building Your Own Application'
        },
        {
            id: 3,
            src: `/images/erp-cloud-${Language}.jpeg`,
            description: Language === 'ar' ? `بناء تطبيق الخاص بك` : 'Building Your Own Application'

        },
        {
            id: 4,
            src: `/images/odoo-${Language}.jpg`,
            description: Language === 'ar' ? `بناء تطبيق الخاص بك` : 'Building Your Own Application'

        },
        {
            id: 5,
            src: `/images/pos-system-${Language}.jpg`,
            description: Language === 'ar' ? `بناء تطبيق الخاص بك` : 'Building Your Own Application'

        },
        {
            id: 6,
            src: `/images/webdev-${Language}.jpg`,
            description: Language === 'ar' ? `بناء تطبيق الخاص بك` : 'Building Your Own Application'

        }
    ])
}

export default loadImages;