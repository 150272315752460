import { ThemeProvider, createTheme } from "@mui/material";
import AppRoutes from "./AppRoutes"
import { LanguageContext } from "./context/LanguageContext";
import { useState } from "react";
import theme from "./styles/theme";

const App = () => {

    const [language, setLanguage] = useState('en')
    const languageValue = { language, setLanguage }

    return (
        <ThemeProvider theme={theme} >
            <LanguageContext.Provider value={languageValue}>
                <AppRoutes />
            </LanguageContext.Provider>
        </ThemeProvider>
    )
}

export default App;